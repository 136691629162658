import fly from 'flyio';
import {ElMessageBox} from 'element-plus';
import siteInfo from "/siteInfo";
import {getToken, removeTokenAndAccount} from "./auth";
import store from '@/store/index.js'

//获取地址栏指定参数的值
function requestGetUrlParam(strName, strHref, strDefault) {
  if (strHref == null || strHref.length < 1) {
    strHref = window.location.href;
  }
  var intPos = strHref.indexOf("?");
  var strRight = strHref.substr(intPos + 1);
  var arrTmp = strRight.split("&");
  for (var i = 0; i < arrTmp.length; i++) {
    var arrTemp = arrTmp[i].split("=");
    if (arrTemp[0].toUpperCase() === strName.toUpperCase()) return arrTemp[1];
  }
  return strDefault;
}

//剔除地址栏指定参数及值
/*function requestDelUrlParam(strName, strHref) {
    if (strHref == null || strHref.length < 1) {
        strHref = window.location.href;
    }
    var intPos = (strHref + "?").indexOf("?");
    var strRight = strHref.substr(intPos + 1);
    var arrTmp = strRight.split("&");
    var hrefstr = "?";
    for (var i = 0; i < arrTmp.length; i++) {
        var arrTemp = arrTmp[i].split("=");
        if (arrTemp[0].toUpperCase() == strName.toUpperCase()) {} else {
            hrefstr += arrTmp[i] + "&";
        }
    }
    hrefstr = hrefstr.substr(0, hrefstr.lastIndexOf('&'));
    return hrefstr;
}*/

const isWq = false; //是否构造微擎地址
/* 适配H5端 */
if (!isWq) {
  if (!siteInfo.uniacid) {
    siteInfo.uniacid = requestGetUrlParam('i');
  }
  if (!siteInfo.server_host) {
    siteInfo.server_host = window.location.protocol + '//' + window.location.hostname;
  }
}
//应用访问路径
const distPath = function (url) {
  if (siteInfo.site_dist) {
    return `/${siteInfo.site_dist}/${url}`;
  } else {
    return `/${url}`;
  }
}
//格式化请求路径
const formatUrl = function (url) {
  url = `${siteInfo.app_name}/${url}`;
  if (isWq) {
    url = `${siteInfo.server_host}?i=${siteInfo.uniacid}&union=${siteInfo.union}&v=${siteInfo.app_version}&from=wxapp&c=entry&a=wxapp&do=api&m=${siteInfo.module_name}&s=${url}`;
    return url;
  } else {
    url = `${siteInfo.server_host}/${url}?i=${siteInfo.uniacid}&union=${siteInfo.union}`;
    return url
  }
}
//设置请求数据类型 application/x-www-form-urlencoded,application/json,text/xml,multipart/form-data
const contentType = function (type) {
  if (type == 'json') {
    fly.config.headers['Content-Type'] = 'application/json; charset=utf-8';
  } else if (type == 'xml') {
    fly.config.headers['Content-Type'] = 'text/xml';
  } else if (type == 'file') {
    fly.config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    fly.config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
}
//设置请求基地址
fly.config.baseURL = siteInfo.server_host;
//设置超时时间 毫秒
fly.config.timeout = 30000;
// 配置请求拦截器
fly.interceptors.request.use((config) => {
  config.headers["Authorization"] = getToken();
  return config;
});
//配置响应拦截器
fly.interceptors.response.use(async function (response) {
    let res = response.data;
    console.log('响应拦截', res);
    if (res.code === 0) {
      return res;
    } else if (res.code === 100) {//授权异常
      ElMessageBox.alert(res.msg, {
        callback: () => {
          removeTokenAndAccount();
          window.location.href = distPath('license');
        }
      });
    } else if (res.code === 101) {//登录异常
      removeTokenAndAccount();
      store.dispatch('toggleLoginModal');
    } else if (res.code === 104) {//无操作权限
      ElMessageBox.alert(res.msg, {
        callback: () => {
          //window.location.href = distPath();
          return false;
        }
      });
    } else {
      ElMessageBox.alert(res.msg, {
        callback: () => {
          return false;
        }
      });
    }
    return false;
  },
  (err) => {
    //发生网络错误，会走到这里
    console.log('err:', err)
    if (err.status === 0) {
      ElMessageBox.alert('网络错误，请刷新后重试！');
    } else if (err.status === 1) {
      ElMessageBox.alert('网络响应超时，请刷新后重试！');
    } else if (err.status === 500) {
      ElMessageBox.alert('服务器错误，请联系开发者！');
    } else if (err.status === 404) {
      ElMessageBox.alert('API不存在！');
    } else if (err.status === 400) {
      ElMessageBox.alert('请求参数错误！');
    } else if (err.status === 401) {
      ElMessageBox.alert('没有访问权限！');
    } else if (err.status === 403) {
      ElMessageBox.alert('禁止访问！');
    } else {
      ElMessageBox.alert('请求响应异常！');
    }
    return false;
  });
//请求接口
const req = {
  //全路径 请求
  async postUrl(url, param, type = '') {
    contentType(type);
    return await fly.post(url, param)
      .then(function (res) {
        //loading.close();
        return res;
      }).catch(function (err) {
        //loading.close();
        //console.log('Error', err);
        return err;
      })
  },
  async post(url, param, type = '') {
    contentType(type);
    url = formatUrl(url);
    //let loading = ElLoading.service();
    return await fly.post(url, param)
      .then(function (res) {
        //loading.close();
        return res;
      }).catch(function (err) {
        //loading.close();
        //console.log('Error', err);
        return err;
      })
  },
  async get(url, param, type = '') {
    contentType(type);
    url = formatUrl(url);
    //let loading = ElLoading.service();
    return await fly.get(url, param)
      .then(function (res) {
        //loading.close();
        return res;
      })
      .catch(function (err) {
        //loading.close();
        //console.log('Error', err);
        return err;
      })
  }
}

export {
  fly,
  req,
  formatUrl,
  distPath
}