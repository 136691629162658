
// 应用参数配置
var siteInfo = {
  'app_name':'ikeyhub',  //应用名称 备注：和官网同一站点时用cms.php
  'app_version': '3.1.3',  //小程序版本
  'union': 'license',  //部署性质  授权license  服务saas
  'union_id':'0',//平台主键ID
  'server_host': 'https://ikeyhub.thistop.top',  //服务器 服务URL
  'site_dist':'',//站点托管文件夹名称

  'module_name':'this_admin',//W7使用
  'uniacid': '-1', //w7填写 -1
  'acid': '1',//w7
  'token': '2023' //将用于接口中的数据安全校验
};

export default siteInfo;

