/*
*验证模块 使用正则表达式 或其它验证方式 用户可自行拓展
*
*/

/**
 * 手机号码验证
 * @param mobile
 * @returns {*}
 */
export function verifyMobile(mobile) {
  // 允许空值，若需必填请添加多验证规则
  if (!mobile) {
    return false;
  }
  if (!/^(1[3-9])\d{9}$/.test(mobile.toString())) {
    return false;
  }
  return true;
}

/**
 * 密码 正则验证
 * @param value
 */
export function verifyPassword(value){
  //密码由字母或数字开头，且只能为字母,数字,下划线及（.）长度在 6 到 18 个字符
  const reg = /^[A-Za-z0-9][A-Za-z0-9_.]{5,17}$/
  if (!value.match(reg)) {
    return false;
  } else {
    return true;
  }
}

/**
 * 手机号码验证
 * @param rule
 * @param mobile
 * @param callback
 * @returns {*}
 */
export function validatorMobile(rule, mobile, callback) {
  // 允许空值，若需必填请添加多验证规则
  if (!mobile) {
    return callback()
  }
  if (!/^(1[3-9])\d{9}$/.test(mobile.toString())) {
    return callback(new Error("手机号格式不正确！"))
  }
  return callback()
}

/**
 * 密码 正则验证
 * @param rule
 * @param value
 * @param callback
 */
export function validatorPassword(rule, value, callback){
  //密码只能由大小写英文字母或数字开头，且由大小写英文字母_.组成
  const reg = /^[A-Za-z0-9][A-Za-z0-9_.]{5,17}$/
  if (!value.match(reg)) {
    callback(new Error('密码由字母或数字开头，且只能为字母,数字,下划线及（.）长度在 6 到 18 个字符'))
  } else {
    callback()
  }
}