/*无需权限数据接口*/

import {req} from '../utils/request';

export class User {

    /**
     * 手机号+验证码登录
     * @param params
     * @returns {Promise<*>}
     */
    userLoginOrReg(params) {
        return req.post('user.user/loginOrReg', params);
    }

    /**
     * 手机号+验证码登录
     * @param params
     * @returns {Promise<*>}
     */
    userLogin(params) {
        return req.post('user.user/login', params);
    }

    /**
     * 手机号+验证码登录
     * @param params
     * @returns {Promise<*>}
     */
    logout(params) {
        return req.post('user.user/logout', params);
    }

    /**
     * 会员账号相关信息
     * @param params
     * @returns {Promise<*>}
     */
    getUserAccountInfo(params) {
        return req.get('user.user/getUserAccountInfo', params);
    }

    /**
     * 会员信息
     * @param params
     * @returns {Promise<*>}
     */
    getUserinfo(params) {
        return req.get('user.user/getUserinfo', params);
    }

    /**
     * 账号 绑定/改绑 邮箱
     * @param params
     * @returns {Promise<*>}
     */
    bindEmail(params) {
        return req.post('user.user/bindEmail', params);
    }

    /**
     * 作品状态统计
     * @param params
     * @returns {Promise<*>}
     */
    getStatusStatistics(params) {
        return req.get('user.user/getStatusStatistics', params);
    }

    /**
     * 获取 用户 作品，关注，收藏 统计
     * @param params
     * @returns {Promise<any|undefined>}
     */
    getAuthorStatistics = async(params) => {
        return req.post('user.user/getAuthorStatistics', params);
    }

    /**
     * 我的作品
     * @param params
     * @returns {Promise<*>}
     */
    getArticleMy(params) {
        return req.get('user.user/getArticleList', params);
    }

    /**
     * 账号 绑定/改绑 手机号
     * @param params
     * @returns {Promise<*>}
     */
    bindPhone(params) {
        return req.post('user.user/bindPhone', params);
    }

    /**
     * 账号 绑定/改绑 微信
     * @param params
     * @returns {Promise<*>}
     */
    bindWechat(params) {
        return req.post('user.user/bindWechat', params);
    }

    /**
     * 账号 修改密码
     * @param params
     * @returns {Promise<*>}
     */
    editPwd(params) {
        return req.post('user.user/editLoginPwd', params);
    }

    /**
     * 账号 修改资料
     * @param params
     * @returns {Promise<*>}
     */
    editUserinfo(params) {
        return req.post('user.user/editUserinfo', params);
    }

    /**
     * 账号 修改用户名
     * @param params
     * @returns {Promise<*>}
     */
    editUsername(params) {
        return req.post('user.user/editUsername', params);
    }

    /**
     * 账号 收藏作品
     * @param params
     * @returns {Promise<*>}
     */
    favoriteArticle(params) {
        return req.post('user.user/favoriteArticle', params);
    }

    /**
     * 账号 取消 收藏作品
     * @param params
     * @returns {Promise<*>}
     */
    cancelFavorite(params) {
        return req.post('user.user/cancelFavorite', params);
    }

    /**
     * 获取 登录用户 收藏作品列表
     * @param params
     * @returns {Promise<*>}
     */
    getFavoriteList(params) {
        return req.post('user.user/getFavoriteList', params);
    }

    /**
     * 获关注作者
     * @param params
     * @returns {Promise<*>}
     */
    attentionUser(params) {
        return req.post('user.user/attentionUser', params);
    }

    /**
     * 取消 关注作者
     * @param params
     * @returns {Promise<*>}
     */
    unfollow(params) {
        return req.post('user.user/unfollow', params);
    }

    /**
     * 获取 登录用户 关注作者列表
     * @param params
     * @returns {Promise<*>}
     */
    getAttentionUserList(params) {
        return req.post('user.user/getAttentionUserList', params);
    }

    /**
     * 获取访客记录
     * @param params
     * @returns {Promise<*|void>}
     */
    getUserVisitor = async (params) => {
        return req.post('user.user/getUserVisitor', params);
    }
}

export default new User();
