import {createApp} from 'vue'
import App from './App.vue'
import store from './store/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import dayjs from "dayjs";
import VueParticles from 'vue-particles';

//引入Vue
const app = createApp(App);
//全局过滤器
app.config.globalProperties.$filters = {
    dateFormat(date, formatStr) {
        return dayjs(date).format(formatStr)
    }
};
//应用路由，注册组件，增加根容器
app.use(ElementPlus)//引入ElementPlus框架
    .use(router)//引入路由
    .use(store) //引入vuex store
    .use(VueParticles)//vue粒子特效
    .mount('#app');

