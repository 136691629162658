<template>
  <!--注册登陆弹出框-->
  <div class="module-mask" v-if="isVisible"></div>
  <div class="module-login" v-if="isVisible">
    <div class="logo">ikeyhub.com</div>
    <div class="slogan">找方案 上咋办</div>
    <div class="title">手机号登录/注册</div>
    <div class="input-area" @keyup.enter.down="keyDown">
      <div class="mod-tel border">
        <img src="/static/iconImg/icon-tel.png" alt="">
        <input type="text" v-model="phone" placeholder="请输入手机号码">
      </div>
      <div class="mod-code">
        <div class="left border">
          <input type="text" v-model="code" placeholder="请输入动态验证码">
        </div>
        <div class="btn-send border" @click="sendVerifyCode">{{ count_down }}</div>
      </div>
      <div class="btn-login this-mouse-pointer" @click="mobileLogin">登录/注册</div>
    </div>
    <!-- 其他登录方式 -->
    <div class="other-login-method">
      <div class="title">其他登录方式</div>
      <div class="cont">
        <div class="item this-mouse-pointer" @click="wxLogin">
          <div class="icons icons01">
            <img src="/static/iconImg/icon-wechat.png" alt="">
          </div>
          <div class="name">微信登录</div>
        </div>
        <div class="item this-mouse-pointer" @click="qqLogin">
          <div class="icons icons02">
            <img src="/static/iconImg/icon-qq.png" alt="">
          </div>
          <div class="name">QQ登录</div>
        </div>
      </div>
    </div>
    <!--关闭按钮-->
    <div class="btn-close this-mouse-pointer" id="J-login-close" @click="showLogin">x</div>
  </div>
</template>

<script>
import {setToken} from "@/utils/auth";
import user from "@/api/user";
import publics from "@/api/public";
import {verifyMobile} from "@/utils/validate"

export default {
  name: 'login_modal',
  components: {},
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      second: 0,
      count_down: '获取验证码',
      phone: '',
      code: '',
      captcha_id: '',
      timer: null
    }
  },
  created() {

  },
  methods: {
    wxLogin(){
      this.$message.warning('微信授权登录功能正在开发中，请先使用手机号+验证码登录！');
    },
    qqLogin(){
      this.$message.warning('QQ授权登录功能正在开发中，请先使用手机号+验证码登录！');
    },
    //enter键提交
    keyDown(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.mobileLogin();
      }
    },
    //登录弹框
    showLogin() {
      this.$store.dispatch('toggleLoginModal');
      this.phone='';
      this.code='';
      clearInterval(this.timer);
    },
    //倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.second > 0) {
          this.second = this.second - 1;
          this.count_down = this.second + 'S';
        } else {
          this.count_down = '获取验证码';
          clearInterval(this.timer);
        }
      }, 1000)
    },
    //发送 短信验证码
    sendVerifyCode() {
      if (this.second > 0) {
        this.$message.error('请勿短时间内频繁操作！');
        return false;
      }
      if (!verifyMobile(this.phone)) {
        this.$message.error('请输入正确格式的手机号！');
        return false;
      }
      //倒计时，防误触
      this.second = 60;
      this.countDown();

      publics.sendSms({
        phone: this.phone
      }).then(res => {
        this.$message.success(res.msg)
      })
    },
    //手机号验证码登录
    mobileLogin() {
      /*if(this.agree!=1){
        this.$common.showModal('请先阅读并同意《用户服务协议》和《隐私政策协议》！');
        return false;
      }*/
      if (!verifyMobile(this.phone)) {
        this.$message.error('请输入正确格式的手机号！');
        return false;
      }
      user.userLoginOrReg({
        username: this.phone,
        code: this.code
      }).then(res => {
        setToken(res.data.token);
        this.$store.dispatch('setAccount',res.data.account);
        this.showLogin();
        location.reload();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.this-mouse-pointer {
  cursor: pointer;
}
.footer {text-align: center; font-size: 12px; color: #999; width: 100%;
  .copyright { padding: 20px; }
}
</style>
