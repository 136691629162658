import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state() {
        return {
            showLoginModal: false,
            keyword: '',
            account: {
                "username": "",
                "realname": "",
                "nickname": "",
                "mobile": "",
                "email": "",
                "avatar": "",
                "residecity": "",
                "profession": ""
            },
        }
    },
    //mutations 是同步函数，用于改变 state 的值
    mutations: {
        /**
         * 全局登录 弹框
         * @param state
         */
        toggleLoginModal(state) {
            state.showLoginModal = !state.showLoginModal;
        },

        /**
         * 搜索关键词
         * @param state
         * @param keyword
         */
        updateKeyword(state, keyword) {
            state.keyword = keyword;
            console.log('keyword:', keyword)
        },

        /**
         * 设置用户信息
         * @param state
         * @param account
         */
        setAccount(state, account) {
            state.account = account;
        },
    },
    //建议使用 actions 来提交 mutations。actions 可以包含任意异步操作
    actions: {
        /**
         * 异步操作 登录框
         * @param commit
         */
        toggleLoginModal({commit}) {
            commit('toggleLoginModal');
        },

        /**
         * 设置 账号信息
         * @param commit
         * @param account
         */
        setAccount({ commit }, account) {
            // 在这里你可以进行异步操作，比如从服务器获取用户信息
            // 然后提交 mutation 来更新状态
            commit('setAccount', account);
        },

        /**
         * 清理登录信息
         * @param commit
         * @param account
         */
        clearAccount({ commit }) {
            commit('setAccount', {});
            localStorage.removeItem('this_user_data');
            localStorage.removeItem('this_user_token');
        },
    },
    // 使用 createPersistedState 插件
    plugins: [
        createPersistedState({
            storage: window.localStorage, // 默认是 localStorage
            key: 'this_user_data', // 你想要存储数据的键（默认是 'vuex'）
            reducer(val) {
                return {
                    // 只保存 account，其他的状态不会被持久化
                    account: val.account
                };
            }
        })
    ],

})

export default store
