/*无需权限数据接口*/
import {req,formatUrl} from '../utils/request';

export class Public {

  /**
   * 发送手机号 验证码
   * @param params
   * @returns {Promise<FlyResponse<any>>}
   */
  sendSms(params){
    return req.post('common/sendSms', params);
  }

  /**
   * 发送邮箱 验证码
   * @param params
   * @returns {Promise<FlyResponse<any>>}
   */
  sendEms(params){
    return req.post('common/sendEms', params);
  }

  /**
   * 获取后台首页数据
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getIndex = async(params) => {
    return req.post('common/getList', params);
  }

  /**
   * 获取验证码图片
   * @returns {Promise<string>}
   */
  captcha = async()=>{
    return formatUrl('common/captcha');
  }

  /**
   * 上传文件
   * @param params
   * @returns {Promise<any|undefined>}
   */
  uploadFile = async(params) => {
    return req.post('user.file/upload', params,'file');
  }

  /**
   * 上传文件 请求路径
   * @returns {Promise<any|undefined>}
   */
  uploadFileUrl = async()=>{
    return formatUrl('user.file/upload');
  }

  /**
   * 获取附件弹框数据
   * @param params
   * @returns {Promise<*>}
   */
  attachmentList = async(params) => {
    return req.get('file/getList' , params)
  }

  /**
   * 获取 省市县
   * @param params
   * @returns {Promise<*>}
   */
  area = async(params) => {
    return req.get('common/area' , params)
  }

}
export default new Public();
