//定义存储token变量
const TokenKey = 'this_user_token';
//定义账号信息存储变量
const AccountKey = 'this_user_account';

//设置token和Account
export function setTokenAndAccount(token, account) {
    localStorage.setItem(TokenKey, token);//会话存储
    localStorage.setItem(AccountKey, JSON.stringify(account));//永久存储
}

//设置token
export function setToken(token) {
    localStorage.setItem(TokenKey, token);//会话存储
}

//设置Account
export function setAccount(account) {
    localStorage.setItem(AccountKey, JSON.stringify(account));//永久存储
}

//获取token
export function getToken() {
    if (localStorage.getItem(TokenKey)) {
        return localStorage.getItem(TokenKey);
    } else {
        return 'no-token';
    }
}

//获取Account
export function getAccount() {
    let accJson = localStorage.getItem(AccountKey);
    if (accJson && accJson != 'undefined') {
        return JSON.parse(accJson);
    } else {
        return {};
    }
}

//移除token 和 Account
export function removeTokenAndAccount() {
    localStorage.removeItem(TokenKey);
    localStorage.removeItem(AccountKey);
}

//移除token
export function removeToken() {
    localStorage.removeItem(TokenKey);
}

//移除Account
export function removeAccount() {
    localStorage.removeItem(AccountKey);
}